import React from "react";
import { useState, useEffect } from "react";
import "./Switch.css";

const Switch = (props) => {
  const toggleTheme = () => {
    props.setToggleState(!props.toggleState);
    const htmlElement = document.documentElement;
    const currentTheme = localStorage.getItem("dmode");

    const newTheme = currentTheme === "light" ? "dark" : "light";

    if (currentTheme !== newTheme) {
      htmlElement.setAttribute("data-theme", newTheme);
      localStorage.setItem("dmode", newTheme);
      window.dispatchEvent(new Event("storage"));
    }
  };

  // Define an onChange handler for the checkbox
  const handleCheckboxChange = () => {
    // You can call toggleTheme here if needed
  };

  return (
    <label
      className="toggle-switch"
      onMouseDown={toggleTheme}>
      <input
        type="checkbox"
        checked={props.toggleState}
        onChange={handleCheckboxChange} // Add the onChange handler
      />
      <span className="switch" />
    </label>
  );
};

export default Switch;
