import React from "react";

const CTA = () => {
  return (
    <div className="cta">
      <a
        href="https://cvster.nl/r/HLfSENVv2"
        target="_blank"
        rel="noreferrer"
        className="btn">
        Download CV
      </a>
      <a
        href="#contact"
        className="btn btn-primary">
        Contact
      </a>
    </div>
  );
};

export default CTA;
