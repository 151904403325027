import React from "react";
import "./about.css";
import Me from "../../assets/profile-pic2.png";
import { FaAward } from "react-icons/fa";
import { FiHexagon } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { MdOutlineDownloading } from "react-icons/md";
import { SiUdemy } from "react-icons/si";
import { GiBrain } from "react-icons/gi";
import { VscFolderLibrary } from "react-icons/vsc";

const achievements_data = [
  {
    id: 1,
    logo: <FiHexagon />,
    title: "Ironhack",
    description: "7 month boot-camp learning the MERN stack",
    diploma: "https://drive.google.com/file/d/14_778-8KC3mgUAcbNmE2m9x8S8dFHI8L/view?pli=1",
    icon: <FaAward />,
  },
  {
    id: 2,
    logo: <GiBrain />,
    title: "Brainnest",
    description: "Basic & Advanced Frontend Development Courses",
    diploma: "https://drive.google.com/file/d/14_778-8KC3mgUAcbNmE2m9x8S8dFHI8L/view?pli=1",
    icon: <FaAward />,
  },
  {
    id: 3,
    logo: <SiUdemy />,
    title: "ûdemy",
    description: "React & FastAPI courses",
  },
  {
    id: 4,
    logo: <VscFolderLibrary />,
    title: "Projects",
    description: "50+ Completed Projects",
  },
];

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img
              src={Me}
              alt="About"
            />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            {achievements_data.map(({ id, logo, title, description, diploma, icon }) => {
              return (
                <article
                  key={id}
                  className="about__card">
                  <div className="about__icon">{logo}</div>
                  <h5>{title}</h5>
                  <small>{description}</small>
                  <div className="about__item-cta">
                    <a
                      className="about__icon"
                      href={diploma}
                      target="_blank"
                      rel="noreferrer">
                      {icon}
                    </a>
                  </div>
                </article>
              );
            })}
          </div>
          <p>Hello there! 👋🏼</p>
          <p>
            I'm a full-stack web developer, who's passionate about creating elegant, functional solutions that meet the needs of users and
            businesses alike.
          </p>
          <p>
            Having pursued a range of projects and opportunities I've honed my skills in web development, allowing me to master key
            technologies such as HTML, CSS, and JavaScript. I'm constantly seeking out new challenges and opportunities to learn and grow,
            and I am committed to staying at the forefront of the field by exploring emerging technologies and best practices.
          </p>
          <p>
            Above all, I am dedicated to creating exceptional user experiences and delivering tangible results for clients and stakeholders.
            Whether I'm building custom applications, optimizing existing systems, or developing responsive websites, my focus is always on
            delivering functional solutions with a sleek, user-friendly design.
          </p>
          <p>
            Overall, I am excited to leverage my skills, experience, and passion for web development to take on new challenges and deliver
            outstanding results in any development role.
          </p>
          <a
            href="#contact"
            className="btn btn-primary">
            Contact
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
