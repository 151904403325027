import React, { useState } from "react";
import "./header.css";
import Switch from "../switch/Switch";
import CTA from "./CTA";
import Myself from "../../assets/profile-pic3.jpeg";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  const [toggleState, setToggleState] = useState(false);
  return (
    <header>
      <div className="container header__container">
        <div>
          <Switch
            toggleState={toggleState}
            setToggleState={setToggleState}
          />
        </div>
        <h5>Hello, my name is</h5>
        <h1>Ståle K. Bjørdal</h1>
        <h5 className="text-light">Junior Full Stack Web Developer</h5>
        <CTA />
        <HeaderSocials />

        <div className="myself">
          <img
            src={Myself}
            alt="profile"
          />
        </div>
        <a
          href="#contact"
          className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
