import React from "react";
import "./footer.css";
import { FaFacebookF } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { FiHexagon } from "react-icons/fi";

const Footer = () => {
  return (
    <footer>
      <a
        href="#top"
        className="footer__logo">
        Ståle K. Bjørdal
      </a>

      <ul className="permalinks">
        <li>
          <a href="#top">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        {/* <li><a href="#services">Services</a></li> */}
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        {/* <a href="https://www.facebook.com/githrall.vicath/" target="_blank" rel="noreferrer"><FaFacebookF/></a> */}
        <a
          href="https://github.com/SnuggleTrouble"
          target="_blank"
          rel="noreferrer">
          <FaGithub />
        </a>
        <a
          href="https://www.linkedin.com/in/staale-bjoerdal/"
          target="_blank"
          rel="noreferrer">
          <BsLinkedin />
        </a>
        <a
          href="https://profile.ironhack.com/staale-bjoerdal"
          target="_blank"
          rel="noreferrer">
          <FiHexagon />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Ståle K. Bjørdal. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
