import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/snake-game-logo.jpg";
import IMG2 from "../../assets/mentat.png";
import IMG3 from "../../assets/film-catalogue.png";
import IMG4 from "../../assets/project-zomboid.png";
import IMG5 from "../../assets/Brainnest-2nd-javascript-project.JPG";
import IMG6 from "../../assets/brainnest-signup-form-img.png";
import IMG7 from "../../assets/brainnest-admin-dashboard-img.png";
import IMG8 from "../../assets/brainnest-portfolio-project-v2.JPG";
import IMG9 from "../../assets/Brainnest-library-app.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Snake Game",
    description: "Play as a ridiculously hungry snake that just can't stop eating fruit. The more fruit you eat the bigger you grow.",
    github: "https://github.com/SnuggleTrouble/Project1-Snake-Game",
    demo: "https://snuggletrouble.github.io/Project1-Snake-Game/",
  },
  {
    id: 2,
    image: IMG2,
    title: "Mentat",
    description: "A CRUD project, built with the MERN-stack. A mock pseudonymous forum for mental health.",
    github: "https://github.com/SnuggleTrouble/Mentat-frontend",
    demo: "https://mentat.netlify.app/landing",
  },
  {
    id: 9,
    image: IMG9,
    title: "Brainnest Library Project",
    description: "A Library App that features CRUD operations in Local Storage. Built with HTML, CSS & JS. Group Project.",
    github: "https://github.com/SnuggleTrouble/brainnest_library_app",
    demo: "https://cbarcan.github.io/Brainnest_library_app/",
  },
  {
    id: 8,
    image: IMG8,
    title: "Brainnest Portfolio Project",
    description: "A responsive portfolio website. Built with HTML, CSS & JS. Group Project.",
    github: "https://github.com/SnuggleTrouble/brainnest-personal-portfolio-v2",
    demo: "https://snuggletrouble.github.io/brainnest-personal-portfolio-v2/",
  },
  {
    id: 5,
    image: IMG5,
    title: "Brainnest Calculator Project",
    description: "A calculator with simple functionalities, focuses on JavaScript and DOM-Manipulation.",
    github: "https://github.com/SnuggleTrouble/brainnest-2nd-javascript-project",
    demo: "https://snuggletrouble.github.io/brainnest-2nd-javascript-project/",
  },
  {
    id: 3,
    image: IMG3,
    title: "Film Catalogue",
    description:
      "A practice website built with HTML, CSS & JavaScript; featuring a limited film catalogue without API. Users can switch between dark & light mode and scroll through the lists of films.",
    github: "https://github.com/SnuggleTrouble/film-catalogue",
    demo: "https://snuggletrouble.github.io/film-catalogue/",
  },
  {
    id: 4,
    image: IMG4,
    title: "Brainnest CSS Project",
    description:
      "A 3 page website built with HTML & CSS, where the goal was to imitate a given design for the landing page, where the other two pages needed to follow key designs.",
    github: "https://github.com/SnuggleTrouble/brainnest-css-project",
    demo: "https://snuggletrouble.github.io/brainnest-css-project/",
  },

  {
    id: 6,
    image: IMG6,
    title: "Brainnest Signup Form",
    description: "A signup form that needed to follow a given design. Built with HTML, CSS & JavaScript. Group Project.",
    github: "https://github.com/SnuggleTrouble/brainnest-signup-form",
    demo: "https://heini-maria.github.io/signup-form/",
  },
  {
    id: 7,
    image: IMG7,
    title: "Brainnest Admin Dashboard",
    description: "An admin dashboard that needed to follow a given design. Built with HTML & CSS. Group Project.",
    github: "https://github.com/SnuggleTrouble/brainnest-admin-dashboard",
    demo: "https://heini-maria.github.io/admin-dashboard/",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, description, github, demo }) => {
          return (
            <article
              key={id}
              className="portfolio__item">
              <div className="portfolio__item-image">
                <img
                  src={image}
                  alt={title}
                />
              </div>
              <div className="portfolio__item-text">
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
              <div className="portfolio__item-cta">
                <a
                  href={github}
                  className="btn"
                  target="_blank"
                  rel="noreferrer">
                  Github
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
