import React from "react";
import "./experience.css";
import { FaCheckCircle } from "react-icons/fa";

const frontendData = [
  { id: 1, topic: "HTML", experience: "Experienced" },
  { id: 2, topic: "CSS", experience: "Experienced" },
  { id: 3, topic: "JavaScript", experience: "Experienced" },
  { id: 4, topic: "React.js", experience: "Experienced" },
  { id: 5, topic: "Bootstrap", experience: "Intermediate" },
  { id: 6, topic: "Tailwind", experience: "Intermediate" },
  { id: 7, topic: "Heroku, Netlify", experience: "Intermediate" },
];

const backendData = [
  { id: 1, topic: "Node.js", experience: "Experienced" },
  { id: 2, topic: "Express.js", experience: "Experienced" },
  { id: 3, topic: "Axios", experience: "Intermediate" },
  { id: 4, topic: "RestAPI", experience: "Experienced" },
  { id: 5, topic: "MongoDB", experience: "Experienced" },
  { id: 6, topic: "Mongoose", experience: "Experienced" },
  { id: 7, topic: "Postman, Compass", experience: "Experienced" },
  { id: 8, topic: "Git, GitHub", experience: "Experienced" },
  { id: 9, topic: "FastAPI", experience: "Beginner" },
  { id: 10, topic: "Python", experience: "Beginner" },
];

const Experience = () => {
  return (
    <section id="experience">
      <h5>The Skills I have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        {/* Frontend Experience */}
        <div className="experience__frontend">
          <h3>Frontend Development</h3>

          <div className="experience__content">
            {frontendData.map(({ id, topic, experience }) => {
              return (
                <article
                  key={id}
                  className="experience__details">
                  <FaCheckCircle className="experience__details-icon" />
                  <div>
                    <h4>{topic}</h4>
                    <small className="text-light">{experience}</small>
                  </div>
                </article>
              );
            })}
          </div>
        </div>

        {/* Backend Experience */}
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {backendData.map(({ id, topic, experience }) => {
              return (
                <article
                  key={id}
                  className="experience__details">
                  <FaCheckCircle className="experience__details-icon" />
                  <div>
                    <h4>{topic}</h4>
                    <small className="text-light">{experience}</small>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
